import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import { Link } from "gatsby";
import buttoncustom from "../../assets/jss/material-kit-react/buttoncustom.jsx";
import AniLink from "gatsby-plugin-transition-link/AniLink";


function CustomButton() {
    return (
      <div>
        {/* <Link className="buttoncustom" to="/location">MORE INFORMATION</Link> */}
        <AniLink className="buttoncustom" paintDrip to="/location"  hex="#192637">
        MORE INFORMATION
</AniLink>
      </div>
    )
  }

  export default withStyles(buttoncustom)(CustomButton);