import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// react components for routing our app without refresh
import { Link } from "gatsby";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// @material-ui/icons
// core components
import Header from "components/Header/Header.jsx";
import Footer from "components/Footer/Footer.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import CustomButton from "components/CustomButtons/CustomButton.jsx";
import DownloadButton from "components/CustomButtons/DownloadButton.jsx";
import SpecButton from "components/CustomButtons/SpecButton.jsx";

import Parallax from "components/Parallax/Parallax.jsx";
// sections for this page
import HeaderLinks from "components/Header/HeaderLinks.jsx";
import SectionBasics from "./Sections/SectionBasics.jsx";
import SectionNavbars from "./Sections/SectionNavbars.jsx";
import SectionTabs from "./Sections/SectionTabs.jsx";
import SectionPills from "./Sections/SectionPills.jsx";
import SectionNotifications from "./Sections/SectionNotifications.jsx";
import SectionTypography from "./Sections/SectionTypography.jsx";
import SectionJavascript from "./Sections/SectionJavascript.jsx";
import SectionCarousel from "./Sections/SectionCarousel.jsx";
import SectionCompletedExamples from "./Sections/SectionCompletedExamples.jsx";
import SectionLogin from "./Sections/SectionLogin.jsx";
import SectionExamples from "./Sections/SectionExamples.jsx";
import SectionDownload from "./Sections/SectionDownload.jsx";
import ProductSection from "./Sections/ProductSection.jsx";

import portrait from "../../assets/img/portrait2.png";
import specshome from "../../assets/img/specshome.png";

import componentsStyle from "assets/jss/material-kit-react/views/components.jsx";

class Components extends React.Component {
  render() {
    const { classes, ...rest } = this.props;
    return (
      <div>
        <Header
          brand="Material Kit React"
          rightLinks={<HeaderLinks />}
          fixed
          color="transparent"
          changeColorOnScroll={{
            height: 400,
            color: "white"
          }}
          {...rest}
        />
        <Parallax image={require("assets/img/hero-image2.png")}>
          <div className={classes.container}>
            <GridContainer>
              <GridItem>
                <div className={classes.brand}>
                <div className={classes.intro}>
                  welcome to
                  </div>
                  <h1 className={classes.title}>COLLIERY</h1>
                  <h1 className={classes.aftertitle}>business park</h1>
                  <div className={classes.subtitle}>
                  New Industrial Units in Llantrisant.
                  Serving Businesses across South Wales and the Cardiff area.

                  </div>
                <CustomButton/>
                </div>
              </GridItem>
            </GridContainer>
          </div>
        </Parallax>

        <div className={classNames(classes.main, classes.mainRaised)}>
          <div className={classes.mainwrap}>
          <ProductSection />
          <GridContainer>
          <GridItem xs={12} sm={4} md={4}>
          <div className={classes.imagewrap}>
          <img alt="..." src={portrait} className={classes.homeimage} />
          </div>
            </GridItem>
          <GridItem xs={12} sm={8} md={8}>
          <div className={classes.preheading}>
                 a new
                  </div>
                 
                <h1 className={classes.heading}>APPROACH</h1>
                <div className={classes.parawrap}>
                <p className={classes.paratext}>
                A high quality development in a high profile location.
                </p>

                <p className={classes.paratext}>
                Colliery Business Park is a new development of industrial, warehousing and urban logistics units in the Llantrisant area.

The launch of Colliery Business Park sets a new level in quality of design and specification in Wales by meeting the requirements of the modern business both regionally and nationally. Our flexible approach and deign offers connectivity and leverages the Park’s accessible location.
                </p>
    
                <p className={classes.paratext}>
                The Park’s layout and design had be moulded and shaped to provide occupiers with a best in class trade and distribution environment offering speed to market to support business growth.

Our simple occupier pack can be downloaded below and more information about Colliery Business Park’s exciting offers can be discussed with our team. 
                </p>

                <br />
               
                <div className={classes.buttonwrap}>
                <DownloadButton/>
                </div>
              </div>
                
            </GridItem>

         
          <GridItem xs={12} sm={8} md={8}>
          <div className={classes.preheadinginverse}>
                 best in class
                  </div>
                 
                <h1 className={classes.headinginverse}>Specification</h1>
                <div className={classes.parawrapinverse}>
                <p className={classes.paratextinverse}>
                Meeting the modern business requirements, Colliery Business Park delivers industrial, warehousing and urban logistics accommodation with unparalleled specification levels locally. 6-8M clear internal heights, open span floor plates and long loading yards offer occupier of the Park the best B1, B2 and B8 accommodation in the market.
                </p>

                <p className={classes.paratextinverse}>
                All units have the option to have office accommodation built in and fitted by the developer and included in the annual rental costs providing a flexible and efficient base for business.
                </p>
    
                <p className={classes.paratextinverse}>
                Our GWYRDD initiative for building and design is intended to provide ongoing benefits to occupiers as environmentally sustainable features such as 15% roof lights are designed to lower energy use and improve cost efficiency in occupiation. 
                </p>

                <br />
               
                <div className={classes.buttonwrap}>            
                <SpecButton/>
                </div>   

                <div className={classes.paddingone}></div>
              </div>
                
            </GridItem>
            <GridItem xs={12} sm={4} md={4}>
          <div className={classes.imagewrap}>
          <img alt="..." src={specshome} className={classes.homeimage} />
          </div>
            </GridItem>
            
            </GridContainer>

            </div>   
        </div>
        <Footer />
      </div>
    );
  }
}

export default withStyles(componentsStyle)(Components);
