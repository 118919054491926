import { container } from "assets/jss/material-kit-react.jsx";

const componentsStyle = {
  container,
  brand: {
    color: "#FFFFFF",
    textAlign: "center",
    border: "30px solid #fff",
    padding: "2rem 0.5rem",
    margin: "27%",
    backdropFilter: "blur(1px)",
    backgroundColor: "rgba(0, 0, 0, 0.35)",
    paddingBottom: "3.5rem",
    "@media (max-width: 1000px)": {
      border: "20px solid #fff",
      margin: "15%",
    },
    "@media (max-width: 500px)": {
      border: "12px solid #fff",
      margin: "10%",
      marginTop: "-200px"
    }
  },
  intro: {
    wordSpacing: "0.2rem",
    fontSize: "1.5rem",
    fontWeight: "300",
    textAlign: "center",
    textTransform: "uppercase",
    marginTop: "1.5rem",
    "@media (max-width: 800px)": {
      fontSize: "0.9rem",
    },
  },
  title: {
    marginTop: "-2px",
    fontSize: "3.4rem",
    fontWeight: "900",
    display: "inline-block",
    position: "relative",
    letterSpacing: "0.02em",
    "@media (max-width: 800px)": {
      fontSize: "2rem",
    }
  },
  aftertitle: {
    marginTop: "-2px",
    fontWeight: "500",
    fontSize: "1rem",
    textTransform: "uppercase",
    marginBottom: "2.5rem",
    "@media (max-width: 800px)": {
      fontSize: "1.2rem",
    }
  },
  subtitle: {
    fontSize: "1rem",
    fontWeight: "200",
    textAlign: "center",
    padding: "0rem 1rem",
    marginBottom: "3.5rem",
    "@media (max-width: 800px)": {
      fontSize: "0.85rem",
    },
  },
  main: {
    background: "linear-gradient(315deg, #dde1e4 10%, #ffffff 20%, #dde1e4 50%, #ffffff 70%, #dde1e4 90%)",
    position: "relative",
    zIndex: "3"
  },
  mainRaised: {
    margin: "-60px 30px 0px",
    borderRadius: "6px",
    boxShadow:
      "0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)",
    "@media (max-width: 600px)": {
      margin: "-60px 16px 0px",
      }
    },
  mainwrap: {
    margin: "auto",
    maxWidth: "2100px",
  },
  link: {
    textDecoration: "none"
  },
  textCenter: {
    textAlign: "center"
  },
  heading: {
    marginTop: "-2px",
    fontSize: "3.4rem",
    fontWeight: "800",
    display: "inline-block",
    position: "relative",
    letterSpacing: "0.02em",
    textTransform: "uppercase",
    color: "#0b292c",
    marginBottom: "4rem",
    marginLeft: "40px",
    "@media (max-width: 1300px)": {
      marginTop: "-2px",
      fontSize: "2rem",
      fontWeight: "800",
      display: "inline-block",
      position: "relative",
      letterSpacing: "0.02em",
      textTransform: "uppercase",
      color: "#0b292c",
      marginBottom: "4rem",
      marginLeft: "20px",
    },
    "@media (max-width: 600px)": {
      marginTop: "-2px",
      fontSize: "2rem",
      fontWeight: "800",
      display: "inline-block",
      position: "relative",
      letterSpacing: "0.02em",
      textTransform: "uppercase",
      color: "#0b292c",
      marginBottom: "4rem",
      marginLeft: "auto",
      width: "100%",
      textAlign: "center",
    }
  },
  preheading: {
    fontSize: "1.5rem",
    textTransform: "uppercase",
    color: "#0b292c",
    opacity: "0.9",
    marginTop: "10%",
    marginBottom: "5px",
    marginLeft: "45px",
    "@media (max-width: 1300px)": {
      fontSize: "1.5rem",
      textTransform: "uppercase",
      color: "#0b292c",
      opacity: "0.9",
      marginTop: "15%",
      marginBottom: "5px",
      marginLeft: "25px",
    },
    "@media (max-width: 600px)": {
      fontSize: "1.5rem",
      textTransform: "uppercase",
      color: "#0b292c",
      opacity: "0.9",
      marginTop: "15%",
      marginBottom: "5px",
      marginLeft: "auto",
      width: "100%",
      textAlign: "center",
    }
  },
  parawrap: {
    maxWidth: "600px",
    position: "relative",
    "@media (max-width: 1300px)": {
      maxWidth: "400px",
      position: "relative",
    },
    "@media (max-width: 600px)": {
      maxWidth: "100%",
      position: "relative",
      marginLeft: "10px",
      marginRight: "10px",
    }
  },
  paratext: {
    fontSize: "14px",
    marginBottom: "1.2rem",
    marginLeft: "40px",
    "@media (max-width: 1300px)": {
      fontSize: "12px",
      marginBottom: "1.2rem",
      marginLeft: "20px",
      marginRight: "20px",
    },
    "@media (max-width: 600px)": {
      fontSize: "12px",
      marginBottom: "1.2rem",
      marginLeft: "20px",
      marginRight: "20px",
      textAlign: "center",
    }
  },
  homeimage: {
    maxWidth: "100%",
    maxHeight: "100%",
    overflow: "hidden",
  },
  imagewrap: {
    width: "100%",
    height: "800px",
    "@media (max-width: 1300px)": {
      width: "100%",
    height: "800px",
    },
    "@media (max-width: 500px)": {
      width: "100%",
    height: "auto",
    }
  },
  headinginverse: {
    marginTop: "-2px",
    fontSize: "3.4rem",
    fontWeight: "800",
    display: "inline-block",
    position: "relative",
    letterSpacing: "0.02em",
    textTransform: "uppercase",
    color: "#0b292c",
    marginBottom: "4rem",
    marginLeft: "40%",
    "@media (max-width: 1300px)": {
      marginTop: "-2px",
      fontSize: "2rem",
      fontWeight: "800",
      display: "inline-block",
      position: "relative",
      letterSpacing: "0.02em",
      textTransform: "uppercase",
      color: "#0b292c",
      marginBottom: "4rem",
      marginLeft: "7%",
    },
    "@media (max-width: 600px)": {
      marginTop: "-2px",
      fontSize: "2rem",
      fontWeight: "800",
      display: "inline-block",
      position: "relative",
      letterSpacing: "0.02em",
      textTransform: "uppercase",
      color: "#0b292c",
      marginBottom: "4rem",
      marginLeft: "auto",
      width: "100%",
      textAlign: "center",
    }
  },
  preheadinginverse: {
    fontSize: "1.5rem",
    textTransform: "uppercase",
    color: "#0b292c",
    opacity: "0.9",
    marginTop: "10%",
    marginBottom: "5px",
    marginLeft: "40.5%",
    "@media (max-width: 1300px)": {
      fontSize: "1.5rem",
      textTransform: "uppercase",
      color: "#0b292c",
      opacity: "0.9",
      marginTop: "120px",
      marginBottom: "5px",
      marginLeft: "7.5%",
    },
    "@media (max-width: 600px)": {
      fontSize: "1.5rem",
      textTransform: "uppercase",
      color: "#0b292c",
      opacity: "0.9",
      marginTop: "150px",
      marginBottom: "5px",
      marginLeft: "auto",
      width: "100%",
      textAlign: "center",
    }
  },
  parawrapinverse: {
    position: "relative",
    marginLeft: "40%",
    marginRight: "40px",
    "@media (max-width: 1300px)": {
      position: "relative",
      marginLeft: "7%",
      marginRight: "40px",
    },
    "@media (max-width: 600px)": {
      maxWidth: "100%",
      position: "relative",
      marginLeft: "25px",
      marginRight: "25px",
    }
  },
  paratextinverse: {
    fontSize: "14px",
    marginBottom: "1.2rem",
    "@media (max-width: 1300px)": {
      fontSize: "12px",
      marginBottom: "1.2rem",
    },
    "@media (max-width: 600px)": {
      fontSize: "12px",
      marginBottom: "1.2rem",
      textAlign: "center",
    }
  },
  paddingone: {
    paddingBottom: "10rem",
  },
  buttonwrap: {
    "@media (max-width: 600px)": {
      marginLeft: "20px",
      marginRight: "20px",
    }
  } 
};



export default componentsStyle;
